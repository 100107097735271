﻿
import { MDCDrawer } from "@material/drawer";
import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCRipple } from '@material/ripple';
import { MDCTextField } from '@material/textfield';
import { MDCMenu } from '@material/menu';
import { MDCMenuSurface } from '@material/menu-surface';
import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';
import { MDCRadio } from '@material/radio';
import { MDCList } from '@material/list';
import { MDCDialog } from '@material/dialog';
import { MDCNotchedOutline } from '@material/notched-outline';
import { MDCTabBar } from '@material/tab-bar';
import { MDCSelect } from '@material/select';

window.rerender = {
    DrawerAppbar: function () {

        const drawer = new MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));
        const topAppBar = new MDCTopAppBar(document.querySelector('.mdc-top-app-bar'));
        topAppBar.setScrollTarget(document.getElementById('main-content'));
        topAppBar.listen('MDCTopAppBar:nav', () => {
            drawer.open = !drawer.open;
        });
        document.getElementById("closeDrawerButton").addEventListener("click", () => {
            drawer.open = !drawer.open;
        })
    },

    menus: function () {
        for (const element of document.querySelectorAll('.mdc-menu-surface')) {
            var sur = new MDCMenuSurface(element);
        }
        for (const element of document.querySelectorAll('.mdc-menu')) {
            var menu = new MDCMenu(element);
        }
        for (const element of document.querySelectorAll('.mdc-list')) {
            var List1 = new MDCList(element);
        }
    },

    Select: function (id) {
        var element = document.getElementById(id);
        var select = new MDCSelect(element);
    },

    FormFields: function () {
        for (const element of document.querySelectorAll('.mdc-button')) {
            var btn = new MDCRipple(element);
        }
        for (const element of document.querySelectorAll('.mdc-form-field')) {
            var formfield = new MDCFormField (element);
        }
        for (const element of document.querySelectorAll('.mdc-radio')) {
            var raio = new MDCRadio (element);
        }
        for (const element of document.querySelectorAll('.mdc-checkbox')) {
            var chkBox =  new MDCCheckbox (element);
        }
        //for (const element of document.querySelectorAll('.mdc-menu-surface')) {
        //    var sur = new MDCMenuSurface(element);
        //}
        //for (const element of document.querySelectorAll('.mdc-menu')) {
        //    var menu = new MDCMenu(element);
        //}
        for (const element of document.querySelectorAll('.mdc-list')) {
            var List1 = new MDCList(element);
        }
        for (const element of document.querySelectorAll('.mdc-dialog')) {
            var dialog = new MDCDialog(element);
        }
        for (const element of document.querySelectorAll('.mdc-notched-outline')) {
            var notchedOutline = new MDCNotchedOutline(element);
        }
        //for (const element of document.querySelectorAll('.mdc-snackbar')) {
        //    var snackbar = new MDCSnackbar(element);
        //}
        
    },

   

    Tabs: function () {
        const tabBar = [].map.call(document.querySelectorAll('.mdc-tab-bar'), function (tabEl) {
            return new MDCTabBar(tabEl);
        });
    }

    //setDisabledEle: function (elementId) {
    //    var chkBox = new MDCCheckbox(elementId);
    //    chkBox.setDisabled(true);
    //},

    //getElementPropertyValue: function (elementId, theProperty) {
    //    //let el = document.querySelector(elementId);
    //    let el = document.getElementById(elementId);
    //    let compStyles = window.getComputedStyle(el);
    //    return compStyles.getPropertyValue(theProperty);
    //},

    //setMDCTextFieldHelperText: function (textFieldId, helperTextId, helperText, inputIsValid) {

    //    //console.log(`textFieldId = ${textFieldId}`);
    //    //console.log(`helperTextId = ${helperTextId}`);
    //    //console.log(`inputIsValid = ${inputIsValid}`);

    //    let elTextField = document.getElementById(textFieldId);
    //    //let el = document.getElementById(helperTextId);

    //    let mdcTextField = new MDCTextField(elTextField);

    //    let foundation = mdcTextField.helperText.foundation;

    //    // Sets the content of the helper text.
    //    foundation.setContent(helperText);

    //    // Sets the validity of the helper text based on the input validity.
    //    foundation.setValidity(true);

    //    // Sets the helper text as a validation message.
    //    foundation.setValidation(!inputIsValid);

    //    // set useNativeValidation = false, so that error message onBlur persist
    //    mdcTextField.useNativeValidation = false;

    //    if (inputIsValid) {
    //        foundation.setPersistent(false);
    //        mdcTextField.valid = true;
    //    }
    //    else {
    //        // Sets the helper text as persistent.
    //        foundation.setPersistent(true);
    //        mdcTextField.valid = false;
    //    }
    //}

   
}
